<template>
    <div>
        <v-btn
            @click="action == 'create' ? save() : edit()"
            :disabled="
                disabled ||
                $get(getServiceModal, 'device.status.name', '') !== 'Aktualny'
            "
            class="buttons buttons--add"
        >
            {{ $t('global:save') }}
        </v-btn>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        action: {
            type: String,
        },
    },
    data() {
        return {
            disabled: false,
        }
    },
    computed: {
        ...mapGetters(['getServiceModal']),
    },
    methods: {
        ...mapActions(['createService', 'updateService']),
        async save() {
            
            this.disabled = true
            let success = await this.createService()
            if (success) {
                this.$emit('closeModal')
                await this.$root.$emit('onServiceAdd')
            }
            this.disabled = false
            if (this.$route.name === 'devices')
                if (success._id)
                    this.$router.push({
                        path: `/service/${success._id}`,
                    })
                else
                    this.$router.push({
                        name: 'service',
                    })
        },
        async edit() {
            
            this.disabled = true
            
            let success = await this.updateService({
                id: this.$route.params.id,
            })
            if (success) {
                await this.$root.$emit('onServiceAdd')
                this.$emit('closeModal')
            }
            this.disabled = false
        },
    },
}
</script>
