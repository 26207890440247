<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" sm="12" class="pa-0">
                <v-stepper v-model="getServiceStep.step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getServiceError.stepOne.validation,
                                ]"
                                editable
                                step="1"
                                >{{
                                    $t('service:deviceInformation')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getServiceError.stepTwo.validation,
                                ]"
                                editable
                                step="2"
                                >{{
                                    $t('service:serviceIntervention')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <!-- MODAL CONTACT -->
                                <Modal
                                    :title="$t('global:addition')"
                                    :open="modalContact.open"
                                    @close="closeModalContact"
                                >
                                    <AEContentContact slot="AEContent" />
                                    <template v-slot:buttons>
                                        <v-btn
                                            @click="addNewContact()"
                                            :disabled="disabled"
                                            @closeModal="closeModalContact"
                                            class="buttons buttons--add"
                                        >
                                            {{ $t('global:save') }}
                                        </v-btn>
                                    </template>
                                </Modal>
                                <!-- MODAL BRANCHES -->
                                <Modal
                                    :title="$t('global:addition')"
                                    :open="modalBranches.open"
                                    @close="closeModalBranches"
                                >
                                    <AEContentBranches slot="AEContent" />
                                    <template v-slot:buttons>
                                        <v-btn
                                            @click="addNewBranches()"
                                            :disabled="disabled"
                                            class="buttons buttons--add"
                                        >
                                            {{ $t('global:save') }}
                                        </v-btn>
                                    </template>
                                </Modal>
                                <!-- MODAL REPRESANTIVE -->
                                <Modal
                                    :title="$t('global:addition')"
                                    :open="modalRepresentative.open"
                                    @close="closeModalRepresentative"
                                >
                                    <AEContentRepresentative slot="AEContent" />

                                    <template v-slot:buttons>
                                        <v-btn
                                            @click="addNewRepresentative()"
                                            :disabled="disabled"
                                            class="buttons buttons--add"
                                        >
                                            {{ $t('global:save') }}
                                        </v-btn>
                                    </template>
                                </Modal>
                                <v-row>
                                    <v-col cols="12">
                                        <v-row class="d-flex justify-center">
                                            <v-col
                                                cols="6"
                                                class="d-flex align-center"
                                            >
                                                <v-combobox
                                                    class="deviceCombobox"
                                                    dense
                                                    :error-messages="
                                                        getServiceError.stepOne
                                                            .fields.model
                                                    "
                                                    :hint="`${$t(
                                                        'global:startWriting'
                                                    )}`"
                                                    :label="`${$t(
                                                        'service:selectDevice'
                                                    )}`"
                                                    @keyup="
                                                        searchDevice(
                                                            $event.target.value
                                                        )
                                                    "
                                                    @input="
                                                        getLastIntervention()
                                                    "
                                                    @change="
                                                        ifClientOperationLocked
                                                    "
                                                    return-object
                                                    :item-text="comboBoxText"
                                                    item-value="_id"
                                                    v-model="
                                                        getServiceModal.device
                                                    "
                                                    :items="devices"
                                                >
                                                    <template v-slot:prepend>
                                                        <v-icon
                                                            color="secondary"
                                                        >
                                                            {{
                                                                $get(
                                                                    getRegistriesTable,
                                                                    'items[0].icon',
                                                                    ''
                                                                )
                                                            }}</v-icon
                                                        > </template
                                                    ><template
                                                        slot="selection"
                                                        slot-scope="data"
                                                    >
                                                        <v-flex shrink>
                                                            <span
                                                                :style="
                                                                    data.item
                                                                        .status
                                                                        .name !==
                                                                    'Aktualny'
                                                                        ? 'text-decoration: line-through'
                                                                        : ''
                                                                "
                                                                class="comboBox-text"
                                                            >
                                                                <div>
                                                                    <span>
                                                                        S/N
                                                                        {{
                                                                            data
                                                                                .item
                                                                                .sn
                                                                        }}
                                                                    </span>
                                                                    <span
                                                                        v-if="
                                                                            data
                                                                                .item
                                                                                .identificationNumber
                                                                        "
                                                                    >
                                                                        <span
                                                                            class="ml-2"
                                                                            >ID:</span
                                                                        >
                                                                        <span
                                                                            class="identificationNumber-ver-1"
                                                                        >
                                                                            {{
                                                                                data
                                                                                    .item
                                                                                    .identificationNumber
                                                                            }}</span
                                                                        >
                                                                    </span>
                                                                </div>
                                                            </span>
                                                        </v-flex>
                                                    </template>
                                                    <template
                                                        slot="item"
                                                        slot-scope="data"
                                                    >
                                                        <span
                                                            :style="
                                                                data.item.status
                                                                    .name !==
                                                                'Aktualny'
                                                                    ? 'text-decoration: line-through'
                                                                    : ''
                                                            "
                                                            class="comboBox-text"
                                                            ><span>S/N:</span>
                                                            {{ data.item.sn }}
                                                            <span
                                                                v-if="
                                                                    data.item
                                                                        .identificationNumber
                                                                "
                                                            >
                                                                <span
                                                                    class="ml-2"
                                                                    >ID:</span
                                                                >
                                                                <span
                                                                    class="identificationNumber-ver-2"
                                                                >
                                                                    {{
                                                                        data
                                                                            .item
                                                                            .identificationNumber
                                                                    }}</span
                                                                >
                                                            </span></span
                                                        >
                                                        <v-tooltip right
                                                            ><template
                                                                v-slot:activator="{
                                                                    on,
                                                                    attrs,
                                                                }"
                                                            >
                                                                <v-icon
                                                                    v-bind="
                                                                        attrs
                                                                    "
                                                                    v-on="on"
                                                                    v-if="
                                                                        data
                                                                            .item
                                                                            .status
                                                                            .name !==
                                                                        'Aktualny'
                                                                    "
                                                                    >mdi-alert-octagon-outline</v-icon
                                                                > </template
                                                            ><span>{{
                                                                `${$t(
                                                                    'service:disabledDeviceInfo'
                                                                )} ${
                                                                    devicesMenager.name
                                                                } ${
                                                                    devicesMenager.lastname
                                                                }`
                                                            }}</span></v-tooltip
                                                        >
                                                    </template>
                                                </v-combobox>
                                                <span
                                                    v-if="
                                                        openServiceBefore &&
                                                        getServiceModal.device
                                                    "
                                                >
                                                    <v-tooltip top>
                                                        <template
                                                            v-slot:activator="{
                                                                on,
                                                            }"
                                                        >
                                                            <v-icon
                                                                class="cursor-pointer"
                                                                color="secondary"
                                                                size="25"
                                                                v-on="on"
                                                                >mdi-alert-circle-outline</v-icon
                                                            >
                                                        </template>
                                                        <span>{{
                                                            `Ten klient posiada już otwarte zlecenie o numerze ${openServiceBeforeindex}`
                                                        }}</span>
                                                    </v-tooltip>
                                                </span>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-6">
                                            <v-col
                                                cols="12"
                                                md="12"
                                                class="margin--deviceInformation"
                                            >
                                                <Separator
                                                    :text="
                                                        $t(
                                                            'service:deviceInformation'
                                                        )
                                                    "
                                                />
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-2">
                                            <v-col
                                                cols="12"
                                                lg="12"
                                                xl="12"
                                                md="12"
                                                sm="12"
                                                class="d-flex flex-column"
                                            >
                                                <SummaryLine
                                                    :paddingBottom="3"
                                                    class="summaryLine--font"
                                                    :firtstLine="
                                                        $t(
                                                            'service:manufacturer-model'
                                                        )
                                                    "
                                                    :text="
                                                        $get(
                                                            getServiceModal,
                                                            'device.manufacturer',
                                                            ''
                                                        ) +
                                                        ' ' +
                                                        $get(
                                                            getServiceModal,
                                                            'device.model.model',
                                                            ''
                                                        )
                                                    "
                                                    :readonly="true"
                                                    :disabled="true"
                                                />

                                                <SummaryLine
                                                    :paddingBottom="15"
                                                    class="summaryLine--font"
                                                    :widthRightSite="400"
                                                    :firtstLine="
                                                        $t('service:deviceType')
                                                    "
                                                    :text="
                                                        $get(
                                                            getServiceModal,
                                                            'device.model.deviceType',
                                                            ''
                                                        )
                                                    "
                                                    :readonly="true"
                                                    :disabled="true"
                                                />
                                                <SummaryLine
                                                    :paddingBottom="3"
                                                    class="summaryLine--font"
                                                    :firtstLine="
                                                        $t('service:priority')
                                                    "
                                                    :text="
                                                        $get(
                                                            getServiceModal,
                                                            'device.priority.field',
                                                            ''
                                                        )
                                                    "
                                                    :readonly="true"
                                                    :disabled="true"
                                                />
                                                <SummaryLine
                                                    :paddingBottom="3"
                                                    class="summaryLine--font"
                                                    :firtstLine="
                                                        $t(
                                                            'service:service-prices'
                                                        )
                                                    "
                                                    :text="
                                                        $get(
                                                            getServiceModal,
                                                            'device.model.servicePrice.name',
                                                            ''
                                                        )
                                                    "
                                                    :readonly="true"
                                                    :disabled="true"
                                                />
                                                <SummaryLine
                                                    :paddingBottom="3"
                                                    class="summaryLine--font"
                                                    :firtstLine="
                                                        $t('service:prices')
                                                    "
                                                    :widthRightSite="400"
                                                    :text="prices"
                                                    :readonly="true"
                                                    :disabled="true"
                                                />
                                                <SummaryLine
                                                    :paddingBottom="25"
                                                    class="summaryLine--font"
                                                    :firtstLine="
                                                        $t(
                                                            'service:serviceBasic'
                                                        )
                                                    "
                                                    :text="
                                                        $get(
                                                            getServiceModal,
                                                            'device.serviceBase.field',
                                                            ''
                                                        )
                                                    "
                                                    :readonly="true"
                                                    :disabled="true"
                                                />
                                                <SummaryLine
                                                    :paddingBottom="3"
                                                    class="summaryLine--font"
                                                    :firtstLine="
                                                        $t('service:client')
                                                    "
                                                    :text="
                                                        $get(
                                                            getServiceModal,
                                                            'device.client.name',
                                                            ''
                                                        )
                                                    "
                                                    :widthRightSite="250"
                                                    :readonly="true"
                                                    :disabled="true"
                                                />
                                                <SummaryLine
                                                    :paddingBottom="25"
                                                    class="summaryLine--font"
                                                    :firtstLine="
                                                        $t('service:address')
                                                    "
                                                    :widthRightSite="350"
                                                    :text="address"
                                                    :readonly="true"
                                                    :disabled="true"
                                                />
                                                <SummaryLine
                                                    :paddingBottom="3"
                                                    class="summaryLine--font"
                                                    :firtstLine="
                                                        $t(
                                                            'service:lastIntervention'
                                                        )
                                                    "
                                                    :text="
                                                        $get(
                                                            lastIntervention,
                                                            'realization.startDate',
                                                            null
                                                        )
                                                            ? $moment(
                                                                  $get(
                                                                      lastIntervention,
                                                                      'realization.startDate',
                                                                      null
                                                                  )
                                                              ).format(
                                                                  'DD-MM-YYYY'
                                                              )
                                                            : ''
                                                    "
                                                    :readonly="true"
                                                    :disabled="true"
                                                />
                                                <SummaryLine
                                                    :paddingBottom="3"
                                                    class="summaryLine--font"
                                                    :firtstLine="
                                                        $t('service:technician')
                                                    "
                                                    :text="
                                                        $get(
                                                            lastIntervention,
                                                            'technician.name',
                                                            ''
                                                        ) +
                                                        ' ' +
                                                        $get(
                                                            lastIntervention,
                                                            'technician.lastname',
                                                            ''
                                                        )
                                                    "
                                                    :readonly="true"
                                                    :disabled="true"
                                                />
                                                <SummaryLine
                                                    :paddingBottom="3"
                                                    class="summaryLine--font"
                                                    :firtstLine="
                                                        $t(
                                                            'service:countersMono'
                                                        )
                                                    "
                                                    :text="
                                                        $get(
                                                            lastIntervention,
                                                            'realization.counters.monoDeviceEnd',
                                                            ''
                                                        )
                                                    "
                                                    :readonly="true"
                                                    :disabled="true"
                                                />
                                                <SummaryLine
                                                    :paddingBottom="3"
                                                    class="summaryLine--font"
                                                    :firtstLine="
                                                        $t(
                                                            'service:countersColor'
                                                        )
                                                    "
                                                    :text="
                                                        $get(
                                                            lastIntervention,
                                                            'realization.counters.colorDeviceEnd',
                                                            ''
                                                        )
                                                    "
                                                    :readonly="true"
                                                    :disabled="true"
                                                />
                                                <SummaryLine
                                                    :paddingBottom="3"
                                                    :widthRightSite="550"
                                                    class="summaryLine--font"
                                                    :firtstLine="
                                                        $t(
                                                            'service:lastInterventionComments'
                                                        )
                                                    "
                                                    :text="
                                                        $get(
                                                            lastIntervention,
                                                            'realization.technicianComments',
                                                            ''
                                                        )
                                                    "
                                                    :readonly="true"
                                                    :disabled="true"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content step="2">
                                <v-row>
                                    <v-col cols="12" md="12" class="pt-0 pb-0">
                                        <Separator
                                            :text="
                                                $t('service:serviceDescription')
                                            "
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12" class="py-0 pt-0">
                                        <v-textarea
                                            v-model="
                                                getServiceModal.clientDescription
                                            "
                                            :error-messages="
                                                getServiceError.stepTwo.fields
                                                    .clientDescription
                                            "
                                            auto-grow
                                            rows="1"
                                            no-resize
                                            :label="`${$t(
                                                'service:customerDescription'
                                            )}*`"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12" class="py-0">
                                        <v-textarea
                                            v-model="
                                                getServiceModal.logisticianDescription
                                            "
                                            auto-grow
                                            rows="1"
                                            no-resize
                                            :label="`${$t(
                                                'service:logisticDescription'
                                            )}`"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <!-- HIDE IF NOTIFY SERVICE -->

                                    <v-col
                                        v-if="
                                            !getServiceModal.createdByHelpDeskUsers
                                        "
                                        cols="8"
                                        md="8"
                                        class="py-0"
                                    >
                                        <v-autocomplete
                                            :label="`${$t(
                                                'service:personContracts'
                                            )}`"
                                            :items="contractors"
                                            ref="presonSelect"
                                            return-object
                                            item-value="_id"
                                            :filter="filter"
                                            v-model="getServiceModal.contractor"
                                        >
                                            <template v-slot:selection="data">
                                                <div class="mr-1">
                                                    {{
                                                        ` ${data.item.name} ${data.item.lastname}`
                                                    }}
                                                </div>
                                                <span
                                                    class="mr-1"
                                                    v-if="data.item.email"
                                                >
                                                    {{
                                                        ` • ${data.item.email} `
                                                    }}
                                                </span>
                                                <span
                                                    v-if="data.item.phoneNumber"
                                                >
                                                    {{
                                                        ` • ${data.item.phoneNumber}`
                                                    }}
                                                </span>
                                            </template>
                                            <template v-slot:item="data">
                                                <div class="mr-1">
                                                    {{
                                                        ` ${data.item.name} ${data.item.lastname}`
                                                    }}
                                                </div>
                                                <span
                                                    class="mr-1"
                                                    v-if="data.item.email"
                                                >
                                                    {{
                                                        ` • ${data.item.email} `
                                                    }}
                                                </span>
                                                <span
                                                    v-if="data.item.phoneNumber"
                                                >
                                                    {{
                                                        ` • ${data.item.phoneNumber}`
                                                    }}
                                                </span>
                                            </template>
                                            <template v-slot:append>
                                                <v-btn
                                                    v-if="
                                                        getServiceModal.contractor
                                                    "
                                                    @mouseup="removeSelected"
                                                    icon
                                                    size="4"
                                                >
                                                    <v-icon size="15"
                                                        >mdi-close</v-icon
                                                    >
                                                </v-btn>
                                                <v-btn icon size="4">
                                                    <v-icon>$dropdown</v-icon>
                                                </v-btn>
                                            </template>
                                            <template v-slot:append-outer>
                                                <v-tooltip bottom>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                        }"
                                                    >
                                                        <v-btn
                                                            icon
                                                            size="4"
                                                            @click="
                                                                openModalRepresentative()
                                                            "
                                                            v-on="on"
                                                        >
                                                            <v-icon
                                                                size="18"
                                                                color="secondary"
                                                                >mdi-account-plus
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{
                                                        $t(
                                                            'devices:addRepresentatives'
                                                        )
                                                    }}</span>
                                                </v-tooltip>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4" class="py-0">
                                        <v-select
                                            :label="`${$t(
                                                'service:preferredTechnic'
                                            )}`"
                                            clearable
                                            :items="technicians"
                                            v-model="
                                                getServiceModal.preferredTechnician
                                            "
                                            item-value="_id"
                                            @click="searchTechnicians"
                                            @click:clear="
                                                $nextTick(
                                                    () =>
                                                        (getServiceModal.preferredTechnician = null)
                                                )
                                            "
                                            ><template v-slot:item="data">
                                                {{
                                                    data.item.name +
                                                    ' ' +
                                                    data.item.lastname
                                                }}
                                            </template>
                                            <template v-slot:selection="data">
                                                {{
                                                    data.item.name +
                                                    ' ' +
                                                    data.item.lastname
                                                }}
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="4" class="py-0">
                                        <v-menu
                                            v-model="expectationsDate"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    v-model="
                                                        getServiceModal.expectedServiceDate
                                                    "
                                                    clearable
                                                    :label="`${$t(
                                                        'service:expectedServiceDate'
                                                    )}`"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                </v-text-field>
                                            </template>
                                            <v-date-picker
                                                first-day-of-week="1"
                                                v-model="
                                                    getServiceModal.expectedServiceDate
                                                "
                                                @input="
                                                    expectationsDate = false
                                                "
                                                :min="
                                                    $moment().format(
                                                        'YYYY-MM-DD'
                                                    )
                                                "
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="2" class="py-0">
                                        <v-menu
                                            ref="menu2"
                                            v-model="expectedTime"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            :return-value.sync="
                                                getServiceModal.expectedServiceTime
                                            "
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    v-model="
                                                        getServiceModal.expectedServiceTime
                                                    "
                                                    clearable
                                                    :label="`${$t(
                                                        'service:time'
                                                    )}`"
                                                    prepend-icon="mdi-clock-time-four-outline"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-time-picker
                                                v-if="expectedTime"
                                                format="24hr"
                                                v-model="
                                                    getServiceModal.expectedServiceTime
                                                "
                                                :min="
                                                    getServiceModal.expectedServiceDate ==
                                                    $moment().format(
                                                        'YYYY-MM-DD'
                                                    )
                                                        ? $moment().format(
                                                              `HH:mm`
                                                          )
                                                        : null
                                                "
                                                full-width
                                                @click:minute="
                                                    $refs.menu2.save(
                                                        getServiceModal.expectedServiceTime
                                                    )
                                                "
                                            ></v-time-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="4" class="py-0">
                                        <v-select
                                            v-model="getServiceModal.technician"
                                            :label="`${$t(
                                                'service:realizingTechnic'
                                            )}`"
                                            clearable
                                            :items="technicians"
                                            @click="searchTechnicians"
                                            @click:clear="
                                                $nextTick(
                                                    () =>
                                                        (getServiceModal.technician = null)
                                                )
                                            "
                                            item-value="_id"
                                        >
                                            <template v-slot:item="data">
                                                {{
                                                    data.item.name +
                                                    ' ' +
                                                    data.item.lastname
                                                }}
                                            </template>
                                            <template v-slot:selection="data">
                                                {{
                                                    data.item.name +
                                                    ' ' +
                                                    data.item.lastname
                                                }}
                                            </template>
                                        </v-select>
                                    </v-col>

                                    <v-col cols="4" class="py-0">
                                        <v-menu
                                            v-model="plannedDate"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    v-model="
                                                        getServiceModal.plannedServiceDate
                                                    "
                                                    clearable
                                                    :label="`${$t(
                                                        'service:plannedServiceDate'
                                                    )}`"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                </v-text-field>
                                            </template>
                                            <v-date-picker
                                                :min="
                                                    $moment().format(
                                                        'YYYY-MM-DD'
                                                    )
                                                "
                                                v-model="
                                                    getServiceModal.plannedServiceDate
                                                "
                                                first-day-of-week="1"
                                                @input="plannedDate = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="2" class="py-0">
                                        <v-menu
                                            ref="menu"
                                            v-model="plannedTime"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            :return-value.sync="
                                                getServiceModal.plannedServiceTime
                                            "
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    v-model="
                                                        getServiceModal.plannedServiceTime
                                                    "
                                                    clearable
                                                    :label="`${$t(
                                                        'service:time'
                                                    )}`"
                                                    prepend-icon="mdi-clock-time-four-outline"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-time-picker
                                                v-if="plannedTime"
                                                format="24hr"
                                                v-model="
                                                    getServiceModal.plannedServiceTime
                                                "
                                                full-width
                                                :min="
                                                    getServiceModal.plannedServiceDate ==
                                                    $moment().format(
                                                        'YYYY-MM-DD'
                                                    )
                                                        ? $moment().format(
                                                              `HH:mm`
                                                          )
                                                        : null
                                                "
                                                @click:minute="
                                                    $refs.menu.save(
                                                        getServiceModal.plannedServiceTime
                                                    )
                                                "
                                            ></v-time-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12" class="mt-5">
                                        <Separator
                                            :text="
                                                $t(
                                                    'service:implementationIntervention'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4" class="pt-0">
                                        <v-checkbox
                                            v-model="
                                                getServiceModal
                                                    .realizationCheckboxes
                                                    .survey
                                            "
                                            class="checkbox--implementation mt-0"
                                            :label="`${$t('service:survey')}`"
                                            color="secondary"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="
                                                getServiceModal
                                                    .realizationCheckboxes
                                                    .repair
                                            "
                                            class="checkbox--implementation"
                                            :label="`${$t('service:repair')}`"
                                            color="secondary"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="
                                                getServiceModal
                                                    .realizationCheckboxes
                                                    .installation
                                            "
                                            class="checkbox--implementation"
                                            :label="`${$t(
                                                'service:implementation'
                                            )}`"
                                            color="secondary"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="
                                                getServiceModal
                                                    .realizationCheckboxes
                                                    .diagnostics
                                            "
                                            class="checkbox--implementation"
                                            :label="`${$t(
                                                'service:diagnostics'
                                            )}`"
                                            color="secondary"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="
                                                getServiceModal
                                                    .realizationCheckboxes
                                                    .itService
                                            "
                                            class="checkbox--implementation"
                                            :label="`${$t(
                                                'service:ItService'
                                            )}`"
                                            color="secondary"
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="4" class="pt-0">
                                        <v-checkbox
                                            v-model="
                                                getServiceModal
                                                    .realizationCheckboxes
                                                    .firstInstallation
                                            "
                                            class="checkbox--implementation mt-0"
                                            :label="`${$t(
                                                'service:firstInstallation'
                                            )}`"
                                            color="secondary"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="
                                                getServiceModal
                                                    .realizationCheckboxes
                                                    .repairWorkshop
                                            "
                                            class="checkbox--implementation"
                                            :label="`${$t(
                                                'service:repairWorkshop'
                                            )}`"
                                            color="secondary"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="
                                                getServiceModal
                                                    .realizationCheckboxes
                                                    .remoteService
                                            "
                                            class="checkbox--implementation"
                                            :label="`${$t('service:remote')}`"
                                            color="secondary"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="
                                                getServiceModal
                                                    .realizationCheckboxes
                                                    .reception
                                            "
                                            class="checkbox--implementation"
                                            :label="`${$t(
                                                'service:reception'
                                            )}`"
                                            color="secondary"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="
                                                getServiceModal
                                                    .realizationCheckboxes
                                                    .supply
                                            "
                                            class="checkbox--implementation"
                                            :label="`${$t('service:supply')}`"
                                            color="secondary"
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="4" class="pt-0">
                                        <v-checkbox
                                            v-model="
                                                getServiceModal
                                                    .realizationCheckboxes
                                                    .continuation
                                            "
                                            class="checkbox--implementation mt-0"
                                            :label="`${$t(
                                                'service:continuation'
                                            )}`"
                                            color="secondary"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="
                                                getServiceModal
                                                    .realizationCheckboxes
                                                    .complaint
                                            "
                                            class="checkbox--implementation"
                                            :label="`${$t(
                                                'service:complaint'
                                            )}`"
                                            color="secondary"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="
                                                getServiceModal
                                                    .realizationCheckboxes
                                                    .urgent
                                            "
                                            class="checkbox--implementation"
                                            :label="`${$t('service:urgent')}`"
                                            color="red"
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="
                                                getServiceModal
                                                    .realizationCheckboxes
                                                    .costInformation
                                            "
                                            class="checkbox--implementation mt-5"
                                            :label="`${$t(
                                                'service:costInformation'
                                            )}`"
                                            color="secondary"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import AEContentContact from './../../../../components/Registries/Contacts/Modal/Content'
import ButtonsContact from './../../../../components/Registries/Contacts/Modal/Buttons'
import AEContentBranches from './../../../../components/Registries/Contacts/Tabs/Branches/Modal/Content'
import ButtonsBranches from './../../../../components/Registries/Contacts/Tabs/Branches/Modal/Buttons'
import AEContentRepresentative from './../../../../components/Registries/Contacts/Tabs/Representatives/Modal/Content'
import ButtonsRepresentative from './../../../../components/Registries/Contacts/Tabs/Representatives/Modal/Buttons'

export default {
    i18nOptions: { namespaces: ['global'] },
    data() {
        return {
            expectationsDate: '',
            expectedTime: '',
            plannedDate: '',
            plannedTime: '',
            date: '',
            time: '',
            hideDelivery: true,
            options: [],
            modalContact: {
                open: false,
            },
            modalBranches: {
                open: false,
            },
            modalRepresentative: {
                open: false,
            },
            disabled: false,
            editable: true,
            devices: [],
            technicians: [],
            devicesMenager: {},
            lastIntervention: {},
            clientHasOperationLocked: '',
            openServiceBefore: false,
            openServiceBeforeindex: '',
        }
    },
    methods: {
        ...mapActions([
            'createContact',
            'createBranch',
            'createRepresentative',
            'fetchBranchesTable',
            'fetchContactsInputs',
            'fetchRepresentativesInput',
            'fetchOperationLockTable',
            'checkIsClientOperationLocked',
        ]),
        ...mapMutations([
            'clearRepresentativesErrors',
            'clearRepresentsativeModal',
            'clearBranchErrors',
            'clearBranchModal',
        ]),
        removeSelected() {
            this.$refs['presonSelect'].reset()
            this.getServiceModal.contractor = null
        },
        filter(item, queryText, itemText) {
            if (item.lastname)
                return (
                    item.name.toLowerCase().includes(queryText.toLowerCase()) ||
                    item.lastname
                        .toLowerCase()
                        .includes(queryText.toLowerCase())
                )
        },
        async ifClientOperationLocked() {
            this.getServiceError.stepOne.fields.model = ''
            const clientId = this.$get(
                this.getServiceModal,
                'device.client._id',
                ''
            )

            if (clientId) {
                const payload = {
                    id: clientId,
                }
                const checkClientOperationLocked = await this.checkIsClientOperationLocked(
                    payload
                )

                if (checkClientOperationLocked.data) {
                    this.getServiceError.stepOne.fields.model =
                        'Klient na liście zablokowanych'

                    this.$forceUpdate()
                } else {
                    this.getServiceError.stepOne.fields.model = ''

                    this.$forceUpdate()
                }
            }
        },
        async searchDevice(search) {
            if (search.length > 3) {
                let response = await new this.Rest('GET', 'devices')
                    .select(
                        'status isDeleted manufacturer model deviceType priority servicePackage serviceBase floor client._id client.representatives client.name client.branches sn deliverer branch identificationNumber'
                    )

                    .populate([
                        {
                            localField: 'model',
                            foreignField: '_id',
                            from: 'models',
                            as: 'model',
                        },
                        {
                            localField: 'model.servicePrice',
                            foreignField: '_id',
                            from: 'service-prices',
                            as: 'model.servicePrice',
                        },
                        {
                            localField: 'client',
                            foreignField: '_id',
                            from: 'contacts',
                            as: 'client',
                        },
                    ])
                    .where({ isDeleted: false })
                    .search(['sn', 'identificationNumber'], search)
                    .send()

                if (!this.devicesMenager.name) {
                    let menager = await new this.Rest(
                        'GET',
                        'registries/devices'
                    )
                        .addParams({ url: true })
                        .select('currentMenager')
                        .populate(['currentMenager'])

                        .send()
                    this.devicesMenager = menager.data.currentMenager
                }
                this.devices = response.data.items
            } else this.devices = []
        },

        async getLastIntervention() {
            if (!this.getServiceModal.device) return
            let date
            if (
                this.getServiceModal.realization &&
                this.getServiceModal.realization.startDate
            )
                date = new Date(this.getServiceModal.realization.startDate)
            if (!date) date = new Date()
            if (this.getServiceModal.device._id) {
                try {
                    let lastService = await new this.Rest(
                        'GET',
                        `service/${this.getServiceModal.device._id}/lastServiceForClient`
                    )
                        .addParams({ date: date })
                        .send()

                    if (lastService?.data?.status?.name == 'Otwarty') {
                        this.openServiceBefore = true
                        this.openServiceBeforeindex = lastService.data.index
                    } else {
                        this.openServiceBefore = false
                    }
                    let lastIntervention = await new this.Rest(
                        'GET',
                        `service/${this.getServiceModal.device._id}/previous`
                    )
                        .populate({
                            path: 'technician',
                        })
                        .addParams({ date: date })
                        .send()

                    if (lastIntervention.data)
                        this.lastIntervention = lastIntervention.data
                } catch (e) {
                    console.log(e)
                }
            }
        },

        async searchTechnicians() {
            if (this.technicians.length > 0) return
            let response = await new this.Rest('GET', `/users`)
                .where({
                    specialRoleName: 'technic',
                })
                .select('_id name lastname')
                .send()

            this.technicians = response.data.items
            // this.technicians = response.data.items
        },
        clearItem() {
            this.getContactsInput.items = []
        },
        closeModalContact() {
            this.modalContact.open = false
        },
        closeModalBranches() {
            this.modalBranches.open = false
            this.clearBranchErrors()
            this.clearBranchModal()
        },
        openModalRepresentative() {
            this.clearRepresentsativeModal()
            this.clearRepresentativesErrors()
            this.modalRepresentative.open = true
        },
        closeModalRepresentative() {
            this.clearRepresentsativeModal()
            this.modalRepresentative.open = false
            this.clearRepresentativesErrors()
        },
        async addNewContact() {
            let success = await this.createContact()
            if (success) {
                let contactSuccess = await this.fetchContactsInputs(
                    '-createdAt'
                )
                if (contactSuccess) {
                    this.getDeviceModal.branch = this.getContactsInput.items[0].branches[0]
                    this.getDeviceModal.client = this.getContactsInput.items[0]
                    this.closeModalContact()
                }
            }
        },
        async addNewRepresentative() {
            if (this.getServiceModal.device.client) {
                let success = await this.createRepresentative({
                    id: this.getServiceModal.device.client._id,
                })
                if (success) {
                    this.modalRepresentative.open = false
                    this.clearRepresentativesErrors()
                    this.clearRepresentsativeModal()
                    await this.fetchRepresentativesInput({
                        id: this.getServiceModal.device.client._id,
                        limit: 300,
                        sort: '-createdAt',
                    })

                    this.getServiceModal.device.client.representatives = this.getRepresentativeInput.items
                    this.getServiceModal.representatives = this.getRepresentativeInput.items[0]
                }
            }
        },
        async addNewBranches() {
            if (this.getDeviceModal.client) {
                let success = await this.createBranch({
                    id: this.getDeviceModal.client._id,
                })
                if (success) {
                    this.modalBranches.open = false
                    this.clearBranchErrors()
                    this.clearBranchModal()
                    await void this.fetchBranchesTable(
                        this.getDeviceModal.client._id
                    )
                    this.getDeviceModal.client.branches = this.getBranchesTable.items
                    this.getDeviceModal.branch = this.getBranchesTable.items[0]
                }
            }
        },
        comboBoxText: item => item.sn + item.identificationNumber,
    },
    components: {
        AEContentContact,
        ButtonsContact,
        AEContentBranches,
        ButtonsBranches,
        AEContentRepresentative,
        ButtonsRepresentative,
    },
    computed: {
        ...mapGetters([
            'getDeviceModal',
            'getServiceStep',
            'getServiceError',
            'getModelsInput',
            'getContactsInput',
            'getBranchesTable',
            'getRepresentativeInput',
            'getServiceStep',
            'getServiceError',
            'getServiceModal',
            'getServicesRegistry',
            'getRegistriesTable',
            'getOperationLockDetails',
            'getServiceDetails',
        ]),

        branches: {
            get() {
                if (this.getDeviceModal.client)
                    return this.getDeviceModal.client.branches.sort((a, b) =>
                        a.name > b.name ? 1 : -1
                    )
                else return []
            },
        },
        representatives: {
            get() {
                if (this.getDeviceModal.client)
                    return this.getDeviceModal.client.representatives.sort(
                        (a, b) => (a.lastname > b.lastname ? 1 : -1)
                    )
                else return []
            },
        },
        prices: {
            get() {
                let prices =
                    this.$get(
                        this.getServiceModal,
                        'device.model.servicePrice'
                    ) || null

                if (prices !== null) {
                    let str = ''
                    str += ` ${Number(
                        this.$get(prices, 'survey.price', 0)
                    ).toFixed(2)} PLN / `
                    str += `${Number(
                        this.$get(prices, 'repair.price', 0)
                    ).toFixed(2)} PLN / `
                    str += `${Number(
                        this.$get(prices, 'installation.price', 0)
                    ).toFixed(2)} PLN / `
                    str += `${Number(
                        this.$get(prices, 'diagnostics.price', 0)
                    ).toFixed(2)} PLN / `
                    str += `${Number(
                        this.$get(prices, 'itService.price', 0)
                    ).toFixed(2)} PLN `
                    return str
                }
            },
        },
        address: {
            get() {
                let branches = this.$get(
                    this.getServiceModal,
                    'device.client.branches',
                    []
                )

                if (!this.getServiceModal.device) return ''
                let branch =
                    branches.find(
                        el => el._id === this.getServiceModal.device.branch
                    ) || this.getServiceModal.device.branch

                if (branch)
                    return `${branch.name} ${branch.address} \n ${
                        branch.postCode
                    } ${branch.city} ${
                        this.getServiceModal.device.floor
                            ? this.getServiceModal.device.floor
                            : ''
                    }`
                else return ''
            },
        },
        contractors: {
            get() {
                let representatives = this.$get(
                    this.getServiceModal,
                    'device.client.representatives',
                    []
                )
                representatives = representatives.filter(e => !e?.isDisabled)
                // return representatives.map(r => `${r.name} ${r.lastname}`)
                return representatives
            },
        },
    },
    async mounted() {
        this.searchTechnicians()
        let menager = await new this.Rest('GET', 'registries/devices')
            .addParams({ url: true })
            .select('currentMenager')
            .populate(['currentMenager'])
            .send()
        this.devicesMenager = menager.data.currentMenager
        await this.getLastIntervention()
        await this.ifClientOperationLocked()
    },
}
</script>
<style scoped lang="sass">
.margin--deviceInformation
    margin-top: 30px
.font--implementation
    font-size: 14px
    color: grey
.summaryLine--font
    font-size: 13px !important
.checkbox--implementation
    height: 20px
    margin-top: 10px
    margin-left: 10px
.deviceCombobox
    margin-top: 20px
.icon-size
    font-size: 20px
    padding-bottom: 2px
    color: #19aa8d
    margin-left: 60px
.comboBox-text
    font-size: clamp(10px,12px,14px)

.identificationNumber-ver-1
    margin-left: -3px
.identificationNumber-ver-2
    margin-left: -1px
</style>
